import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 528 496">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">
          
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M158.350525,205.097260 
	C167.467392,204.013611 168.858887,202.491867 168.866776,193.620926 
	C168.889923,167.622772 168.893127,141.624573 168.875092,115.626419 
	C168.869781,107.960793 167.167709,106.046463 159.911774,104.192268 
	C156.731674,103.379616 151.461563,104.248642 152.434891,98.509811 
	C153.293457,93.447655 157.858734,95.784081 160.709076,95.763840 
	C186.539291,95.580345 212.372162,95.760223 238.202042,95.555000 
	C242.993378,95.516937 244.707718,96.888863 244.395584,101.767799 
	C244.002716,107.908730 244.340149,114.094460 244.289322,120.260262 
	C244.271988,122.361954 244.679855,124.824356 241.544601,125.262329 
	C238.798447,125.645950 236.600143,125.016899 235.518524,122.127106 
	C235.111771,121.040329 234.486740,120.036697 233.985687,118.983505 
	C229.142563,108.803680 222.175934,102.377304 209.803055,103.923592 
	C204.223846,104.620850 198.484970,104.085594 192.819366,104.028511 
	C189.689224,103.996971 188.325211,105.348923 188.347885,108.550354 
	C188.430481,120.215546 188.416626,131.881943 188.344711,143.547333 
	C188.326523,146.495941 189.495529,147.865479 192.488373,147.753983 
	C194.817673,147.667206 197.153809,147.774292 199.486923,147.776535 
	C211.574692,147.788193 214.515472,145.608490 217.959808,133.974762 
	C218.719711,131.408035 219.023026,128.609024 222.825363,128.892838 
	C225.372345,129.082947 226.970154,130.041794 226.969406,132.790070 
	C226.965759,145.955185 226.963928,159.120361 226.926086,172.285400 
	C226.918900,174.784515 225.771149,176.406052 223.090546,176.599747 
	C220.242584,176.805481 219.121613,175.460724 218.453308,172.657501 
	C215.113007,158.646347 205.144287,152.886978 191.025085,156.426666 
	C188.939423,156.949539 188.413239,158.265594 188.411270,160.067429 
	C188.398544,171.732819 188.232758,183.402039 188.487061,195.061935 
	C188.602554,200.357330 192.040665,204.128555 197.166519,204.419357 
	C205.968597,204.918732 214.798126,204.922760 223.625076,204.294815 
	C233.316879,203.605331 236.598114,196.000305 240.538635,189.093704 
	C242.019791,186.497696 243.372055,183.770523 244.332031,180.949661 
	C245.477158,177.584778 247.887848,177.375046 250.700699,177.696915 
	C254.080612,178.083694 253.262589,180.777374 253.143723,182.728683 
	C252.597473,191.696045 251.759171,200.646103 251.254776,209.615265 
	C251.070709,212.888062 249.506851,213.994186 246.529648,213.988968 
	C217.198898,213.937592 187.867905,213.944382 158.537735,213.788910 
	C156.153915,213.776291 152.764175,214.431702 152.284973,210.483200 
	C151.770203,206.241577 154.847977,205.662689 158.350525,205.097260 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M342.960907,392.059357 
	C332.424805,376.208435 320.568878,361.895782 307.869629,348.176392 
	C305.705994,349.621429 306.435883,351.636505 306.416626,353.301453 
	C306.314423,362.130554 306.458282,370.963074 306.305328,379.790771 
	C306.227112,384.305359 307.866760,387.147095 312.509521,387.931580 
	C313.981842,388.180359 315.408264,388.690948 316.875824,388.979492 
	C319.360687,389.468079 320.033447,391.181244 319.938416,393.394806 
	C319.842072,395.638306 318.409180,396.637177 316.360931,396.804474 
	C314.539062,396.953278 312.702423,396.957764 310.872162,396.961548 
	C301.042267,396.981903 291.212250,397.015839 281.382629,396.966980 
	C278.714478,396.953705 275.834106,397.094910 275.725739,393.088562 
	C275.628357,389.488586 278.101807,389.267670 280.688538,388.912109 
	C287.642975,387.956116 288.704590,386.713287 288.710388,379.569550 
	C288.729004,356.577423 288.563416,333.583344 288.846893,310.594635 
	C288.915649,305.017334 286.751526,302.193420 281.656982,300.979370 
	C280.529602,300.710693 279.457855,300.213379 278.354095,299.840149 
	C276.287567,299.141357 275.588348,297.665741 275.779846,295.573486 
	C276.005829,293.104065 277.734650,292.752502 279.681427,292.677856 
	C282.676971,292.562866 285.673187,292.404114 288.669739,292.388000 
	C297.326935,292.341400 305.984650,292.390808 314.641632,292.326508 
	C317.147766,292.307922 319.410645,292.435760 319.843842,295.587799 
	C320.312012,298.994232 318.162872,299.786987 315.399323,300.253052 
	C307.467438,301.590698 306.349182,302.979034 306.334595,311.076874 
	C306.320801,318.740875 306.307739,326.405060 306.353210,334.068817 
	C306.363708,335.838104 305.921570,337.766937 308.047241,339.804596 
	C313.087280,335.928284 317.922943,331.747009 322.317047,327.080231 
	C327.791626,321.266022 333.485168,315.635803 337.939240,308.926270 
	C342.011383,302.792053 341.653107,301.735657 334.526978,300.113251 
	C332.901947,299.743256 331.033600,299.650208 330.522095,297.687897 
	C329.740906,294.690857 330.744263,292.753052 334.127472,292.688538 
	C344.615753,292.488586 355.104034,292.273834 365.593292,292.152008 
	C368.041351,292.123596 370.271271,292.559326 370.324280,295.899811 
	C370.373413,298.996552 368.575104,299.941711 365.980743,300.227264 
	C359.155090,300.978516 354.139038,304.408447 349.590698,309.597290 
	C341.922852,318.344940 334.587219,327.450653 325.870728,335.228912 
	C323.362396,337.467255 323.176361,339.017426 325.491211,341.746674 
	C334.860077,352.792694 343.927032,364.094513 353.122437,375.287964 
	C358.454590,381.778778 363.876740,388.104065 373.069641,389.102875 
	C375.510742,389.368073 376.212219,391.287506 376.037811,393.545990 
	C375.860474,395.842651 374.312592,396.758179 372.311523,396.776611 
	C364.484955,396.848755 356.656647,396.858551 348.830383,396.769409 
	C345.951477,396.736633 344.584839,394.442383 342.960907,392.059357 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M198.492935,258.328796 
	C214.202835,250.577179 230.825516,247.241989 247.388184,243.471680 
	C267.000153,239.007202 286.417419,233.688797 305.926178,228.768463 
	C308.874725,228.024796 311.737885,226.513153 314.844208,227.246170 
	C315.235535,228.515976 314.685547,229.144684 314.007385,229.654526 
	C299.637238,240.457169 284.258087,248.672409 266.322784,252.569748 
	C246.708099,256.832031 227.196960,261.885315 209.699997,272.689484 
	C188.102280,286.025848 181.049911,309.364777 188.502518,331.170868 
	C195.703552,352.240814 210.677658,365.101318 233.173203,368.354614 
	C234.151199,368.496033 235.179810,368.408112 236.018448,369.345703 
	C235.839783,371.445221 234.042389,371.721466 232.519775,372.090637 
	C207.967392,378.042847 178.710739,363.202332 167.983887,340.069519 
	C155.081085,312.244232 163.412674,282.520264 187.670914,265.270905 
	C191.058807,262.861877 194.664597,260.759308 198.492935,258.328796 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M298.707947,165.282898 
	C294.985107,162.993134 291.481934,161.096420 287.562378,160.080780 
	C283.734619,159.088928 279.918579,158.052032 275.761353,156.945465 
	C276.847931,154.452301 278.955322,154.195053 280.744720,154.030899 
	C300.372894,152.230103 317.910065,157.127472 331.582062,171.807098 
	C360.681396,203.051132 350.296387,250.104965 310.719604,268.585144 
	C294.518036,276.150391 277.223846,279.173309 259.900726,282.410370 
	C248.317886,284.574799 236.817337,287.179535 225.279495,289.585114 
	C215.359177,291.653442 205.437469,293.715118 195.518616,295.790466 
	C194.389938,296.026642 193.272980,296.402466 192.098969,295.714935 
	C192.402100,293.037872 194.773331,292.480377 196.412415,291.237732 
	C213.827927,278.034546 234.461243,273.785309 255.252838,269.590118 
	C273.233002,265.962158 290.861328,260.994446 305.847382,249.560837 
	C326.046021,234.150253 329.719330,207.102203 317.541748,185.353928 
	C312.993866,177.231720 307.008667,170.406326 298.707947,165.282898 
z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
